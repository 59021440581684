var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "postForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "封面图" } },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadURL,
                          imgUrls: _vm.form.picId,
                          fileSize: 0.5,
                        },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "picId", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "picId", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " 注：建议宽690px高294px，图片小于512KB"
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "帖子简介",
                        prop: "topicContent",
                        rules: [
                          {
                            required: true,
                            message: "请输入帖子简介",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请输入帖子简介",
                          maxlength: 100,
                        },
                        model: {
                          value: _vm.form.topicContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "topicContent", $$v)
                          },
                          expression: "form.topicContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "内容",
                        prop: "body",
                        rules: [
                          {
                            required: true,
                            message: "输入内容不能为空",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-ueditor", {
                        model: {
                          value: _vm.form.body,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "body", $$v)
                          },
                          expression: "form.body",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发布项目",
                        prop: "communityId",
                        rules: [
                          {
                            required: true,
                            message: "输入内容不能为空",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询项目",
                              width: _vm.width,
                            },
                            on: { onChange: _vm.communityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属话题",
                        rules: [
                          {
                            required: true,
                            message: "选项不能为空",
                            trigger: "change",
                          },
                        ],
                        prop: "topicType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.topicTypeOps, width: _vm.width },
                        model: {
                          value: _vm.form.topicType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "topicType", $$v)
                          },
                          expression: "form.topicType",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isAdd
                    ? _c("el-form-item", { attrs: { label: "是否置顶" } }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.isTopMap[_vm.form.isTop])),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            rules: [
                              {
                                required: true,
                                message: "选项不能为空",
                                trigger: "change",
                              },
                            ],
                            prop: "topicStatus",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.topicStatusOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.topicStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "topicStatus", $$v)
                              },
                              expression: "form.topicStatus",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }