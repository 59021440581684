<!--
 * @Description: 内容发布--资讯帖子--帖子表单页面
 * @Author: 小广
 * @Date: 2019-06-21 15:10:05
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-08-09 14:47:08
 -->
<template>
  <div class="postForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="封面图">
            <v-uploader
              :action="uploadURL"
              :imgUrls.sync="form.picId"
              :fileSize="0.5"
            >
              <template #tip>
                <span> 注：建议宽690px高294px，图片小于512KB</span>
              </template>
            </v-uploader>
          </el-form-item>
          <el-form-item
            label="帖子简介"
            prop="topicContent"
            :rules="[
              { required: true, message: '请输入帖子简介', trigger: 'blur' }
            ]"
          >
            <v-textarea
              v-model="form.topicContent"
              placeholder="请输入帖子简介"
              :maxlength="100"
            ></v-textarea>
          </el-form-item>
          <el-form-item
            label="内容"
            prop="body"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                trigger: 'change'
              }
            ]"
          >
            <v-ueditor v-model="form.body"></v-ueditor>
          </el-form-item>
          <el-form-item
            label="发布项目"
            prop="communityId"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                trigger: 'change'
              }
            ]"
          >
            <v-select2
              placeholder="查询项目"
              v-model="form.communityId"
              v-bind="communityParams"
              :width="width"
              @onChange="communityChange"
            ></v-select2>
          </el-form-item>
          <el-form-item
            label="所属话题"
            :rules="[
              { required: true, message: '选项不能为空', trigger: 'change' }
            ]"
            prop="topicType"
          >
            <v-select
              v-model="form.topicType"
              :options="topicTypeOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item v-if="!isAdd" label="是否置顶">
            <div>{{isTopMap[form.isTop]}}</div>
          </el-form-item>
          <el-form-item
            v-if="!isAdd"
            label="状态"
            :rules="[
              { required: true, message: '选项不能为空', trigger: 'change' }
            ]"
            prop="topicStatus"
          >
            <v-select
              v-model="form.topicStatus"
              :options="topicStatusOps"
              :width="width"
            />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { createPostURL, editPostURL, queryPostURL, uploadURL, getTopicListURL } from './api'
import { isTopMap, topicStatusMap, topicStatusOps } from './map'
import { vUploader, vUeditor } from 'components/control'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { mapHelper } from 'common/utils'
import { normalCommunityParams } from 'common/select2Params'

export default {
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    vUeditor
  },
  data () {
    return {
      width: 182,
      uploadURL: uploadURL,
      isTopMap: isTopMap,
      topicStatusMap: topicStatusMap,
      topicStatusOps: topicStatusOps().slice(0, 2),
      topicTypeOps: [],
      communityParams: normalCommunityParams,
      form: {
        id: undefined,
        picId: '',
        topicContent: '',
        body: '',
        communityId: '',
        topicStatus: 1,
        topicType: undefined,
        isTop: undefined
      },
      submitConfig: {
        queryUrl: '',
        submitUrl: '',
        submitMethod: ''
      },
      isAdd: false
    }
  },

  created () {
    const { id } = this.$route.query
    this.isAdd = !id
    this.submitConfig = {
      queryUrl: queryPostURL,
      submitUrl: this.isAdd ? createPostURL : editPostURL,
      submitMethod: 'post',
      submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
    }
    let title = this.isAdd ? '新增' : '编辑'
    // 设置title
    this.$setBreadcrumb(title)
  },

  mounted () {
    if (!this.isAdd) {
      const { id } = this.$route.query
      this.$refs.formPanel.getData({ id })
    }
  },
  methods: {
    update (data) {
      if (data.annexs && data.annexs.length) {
        data.picId = data.annexs[0].name
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },

    submitBefore (data) {
      if (this.isAdd) {
        delete data.isTop
        delete data.id
      }
      if (!data.picId || data.picId.length === 0) {
        delete data.picId
      }
      data.communityId = JSON.stringify([data.communityId])
      return true
    },

    communityChange (value, echo) {
      this.getTopicList(!echo)
    },

    // 获取话题 getDefalut取默认值
    getTopicList (getDefault) {
      let _this = this
      let requestUrl = `${getTopicListURL}/${this.form.communityId}`
      this.$axios
        .get(requestUrl)
        .then(res => {
          if (res.status === 100) {
            let { data } = res
            let ops = data.map(val => {
              return {
                text: val.text,
                value: parseInt(val.value)
              }
            })
            const { setOps: topicTypeOps } = mapHelper.setMap(ops)
            if (topicTypeOps().length > 0) {
              if (getDefault) {
                _this.form.topicType = topicTypeOps()[0].value
              }
            } else {
              if (getDefault) {
                _this.form.topicType = undefined
              }
            }
            _this.topicTypeOps = topicTypeOps()
          }
        })
    }
  }
}
</script>
